import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'o8-info-snack-message',
  templateUrl: './info.snack-message.component.html',
  styleUrls: ['./info.snack-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatSnackBarModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoSnackBarComponent {
  msg!: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { msg: string },
  ) {
    this.msg = this.data.msg;
  }
}
