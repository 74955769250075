import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'o8-action-snack-message',
  templateUrl: './action.snack-message.component.html',
  styleUrls: ['./action.snack-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatSnackBarModule, MatButtonModule],
})
export class ActionSnackMessageComponent {
  showDismissBtn = true;
  showActionBtn = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { msg: string; actionText: string; showDismissBtn: boolean },
    public snackBarRef: MatSnackBarRef<ActionSnackMessageComponent>,
  ) {
    this.showDismissBtn = this.data.showDismissBtn;
    this.showActionBtn = !!this.data.actionText;
  }
}
